import React from 'react'

import Container from '../Container'
import { StyledFooter, StyledFlex, StyledBox } from './index.styles'
import VNGLogo from '../../images/svg/vng-logo.svg'

const Footer = () => (
  <StyledFooter>
    <Container>
      <StyledFlex>
        <StyledBox textAlign="left">
          <ul>
            <li><a href="https://developer.overheid.nl">developer.overheid.nl</a></li>
            <li><a href="https://www.vngrealisatie.nl/over-vngrealisatie">Over VNG Realisatie</a></li>
            <li><a href="https://workshops.this-way.nl/">Workshops This Way Cartografie</a></li>
            <li><a href="https://kennisnetwerkdata.pleio.nl/groups/view/25287301-1370-4ac2-8eb7-16970423b23e/data-en-energie/events">DEGO Agenga</a></li>
          </ul>
        </StyledBox>
        <StyledBox textAlign="right">
	  <VNGLogo />
        </StyledBox>
      </StyledFlex>
    </Container>
  </StyledFooter>
)

export default Footer
