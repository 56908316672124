import styled from 'styled-components/macro'
import { Link } from 'gatsby'

import Container from '../Container'

export const StyledHeader = styled.header`
  padding-top: ${(p) => p.theme.tokens.spacing05};
  padding-bottom: ${(p) => p.theme.tokens.spacing05};
  border-bottom: 1px solid rgba(0,0,0,.15);
  box-shadow: 0 1px 1px rgba(27,31,35,.1);
`

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
`

export const StyledLogoLink = styled(Link)`
  display: flex;
  align-items: center;
  text-decoration: none;
  color: #000;
  span {
    padding-left: 1em;
    color: #000;
    text-decoration: none;
    font-weight: ${(p) => p.theme.tokens.fontWeightSemiBold};
    font-size: 20px;
  }
`
