import PropTypes from 'prop-types'
import React from 'react'
import Logo from "../../images/svg/logo.svg"

import { StyledHeader, StyledContainer, StyledLogoLink} from './index.styles'

const Header = ({ siteTitle }) => (
  <StyledHeader>

  <script data-goatcounter="https://geitjes.commondatafactory.nl/count"
        async src="//geitjes.commondatafactory.nl/count.js"></script>

    <StyledContainer>
      <StyledLogoLink to="/">
	<Logo/>
        <span>{siteTitle}</span>
      </StyledLogoLink>
    </StyledContainer>
  </StyledHeader>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: `CDF`,
}

export default Header
